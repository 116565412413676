import './src/styles/global.css';

import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { broadcastQueryClient } from 'react-query/broadcastQueryClient-experimental';
import { createWebStoragePersistor } from 'react-query/createWebStoragePersistor-experimental';
import { ReactQueryDevtools } from 'react-query/devtools';
import { persistQueryClient } from 'react-query/persistQueryClient-experimental';

const queryClient = new QueryClient();

if (typeof window !== 'undefined') {
  const localStoragePersistor = createWebStoragePersistor({
    storage: window.localStorage
  });

  persistQueryClient({
    queryClient,
    persistor: localStoragePersistor,
  });
}

broadcastQueryClient({
  queryClient,
  broadcastChannel: 'adenin-digital-assistant',
});

if (typeof document !== 'undefined' && typeof navigator !== 'undefined' && navigator.userAgent.toLowerCase().indexOf('windows') > -1) {
  document.onreadystatechange = () => {
    if (document.readyState != "complete") return;
    document.documentElement.setAttribute('class', 'platform-windows');
  };
}

export const wrapRootElement = ({ element }) => {
  return (
    <QueryClientProvider client={queryClient}>
      {element}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
};
