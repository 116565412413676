// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-app-jsx": () => import("./../../../src/pages/app.jsx" /* webpackChunkName: "component---src-pages-app-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-links-jsx": () => import("./../../../src/pages/links.jsx" /* webpackChunkName: "component---src-pages-links-jsx" */),
  "component---src-pages-m-365-links-jsx": () => import("./../../../src/pages/m365-links.jsx" /* webpackChunkName: "component---src-pages-m-365-links-jsx" */),
  "component---src-pages-session-jsx": () => import("./../../../src/pages/session.jsx" /* webpackChunkName: "component---src-pages-session-jsx" */),
  "component---src-pages-test-jsx": () => import("./../../../src/pages/test.jsx" /* webpackChunkName: "component---src-pages-test-jsx" */),
  "component---src-pages-theme-colors-jsx": () => import("./../../../src/pages/theme/colors.jsx" /* webpackChunkName: "component---src-pages-theme-colors-jsx" */),
  "component---src-pages-theme-components-jsx": () => import("./../../../src/pages/theme/components.jsx" /* webpackChunkName: "component---src-pages-theme-components-jsx" */),
  "component---src-pages-theme-typography-jsx": () => import("./../../../src/pages/theme/typography.jsx" /* webpackChunkName: "component---src-pages-theme-typography-jsx" */),
  "component---src-pages-worldclock-jsx": () => import("./../../../src/pages/worldclock.jsx" /* webpackChunkName: "component---src-pages-worldclock-jsx" */)
}

